var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":_vm.label,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"outlined":_vm.outlined,"readonly":_vm.readonly,"items":_vm.items,"item-text":_vm.item_text,"item-value":_vm.item_value,"persistent-hint":"","multiple":"","error-messages":errors,"loading":_vm.loading},scopedSlots:_vm._u([(_vm.items.length > 0)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{staticClass:"py-0 my-0",attrs:{"ripple":""},on:{"click":_vm.toggleAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.items_value.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Seleccionar todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,(_vm.compress)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item[_vm.item_text]))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.items_value.length - 1)+" otros) ")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{key:index},[_c('span',[_vm._v(_vm._s(item[_vm.item_text]))])])]}}],null,true),model:{value:(_vm.items_value),callback:function ($$v) {_vm.items_value=$$v},expression:"items_value"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }